const ABORT_REQUEST_CONTROLLERS = new Map();

function abortRequestSafe(key, reason = 'CANCELLED') {
    ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

function abortAndGetSignalSafe(key) {
    abortRequestSafe(key); // abort previous request, if any
    const newController = new AbortController();
    ABORT_REQUEST_CONTROLLERS.set(key, newController);
    return newController.signal;
}

const createRequestObject = (
    method = 'GET',
    path = '',
    body = null,
    isFormData = false
) => {
    const requestOptions = {
        method,
        headers: { 'Content-Type': 'application/json' },
    };

    if (body) {
        requestOptions.body = JSON.stringify(body);
    }

    if (isFormData) {
        delete requestOptions.headers;
        requestOptions.body = body;
    }

    const pathWithoutQueryParams = path.split('?');
    const signalKey =
        window.location.pathname +
        method +
        pathWithoutQueryParams[0] +
        isFormData;

    requestOptions.signal = abortAndGetSignalSafe(signalKey);
    return { requestOptions };
};

async function get(path) {
    const { requestOptions } = createRequestObject(
        'GET',
        path
    );

    const result = await fetchApi(path, requestOptions);
    return result;
}

async function post(path, body = {}, isFormData = false) {
    const { requestOptions } = createRequestObject(
        'POST',
        path,
        body,
        isFormData
    );

    const result = await fetchApi(path, requestOptions);
    return result;
}

async function put(path, body = {}, isFormData = false) {
    const { requestOptions } = createRequestObject(
        'PUT',
        path,
        body,
        isFormData
    );

    const result = await fetchApi(path, requestOptions);
    return result;
}

async function patch(
    path,
    body = {},
    isFormData = false
) {
    const { requestOptions } = createRequestObject(
        'PATCH',
        path,
        body,
        isFormData
    );

    const result = await fetchApi(path, requestOptions);
    return result;
}

async function remove(path) {
    const { requestOptions } = createRequestObject(
        'DELETE',
        path
    );

    const result = await fetchApi(path, requestOptions);
    return result;
}

async function fetchApi(path, requestOptions) {
    return fetch(path, requestOptions)
        .then((res) => {
            const responseType =
                res.headers.get('Content-Type') ||
                res.headers.get('content-type');

            if (responseType.indexOf('application/json') === -1) {
                return res.blob();
            }
            return res.json();
        })
        .catch((err) => {
            return {
                error: true,
                continueLoading: err.name === 'AbortError',
                message:
                    err.name === 'AbortError' ? 'Abort Request' : err || '',
            };
        });
}

const APIServer = {
    get,
    post,
    put,
    patch,
    remove,
    fetchApi
};

export default APIServer;
