import RenderRoutes from './routes'
import './App.css';
import NavBar from './Components/AppBar/appbar';

function App() {
  return (
    <>
      <NavBar />
      <RenderRoutes />
    </>
  );
}

export default App;
