import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Chip,
    OutlinedInput,
    Box,
    Checkbox,
    ListItemText,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function MultiSelect({
    label,
    value,
    defaultValue,
    name,
    onChange,
    options,
    option_disable,
    error = null,
    disabled = false,
    variant = 'outlined',
    selectAllIntegrate = false,
    ...other
}) {
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        if (selectAllIntegrate) {
            if (value !== selected) {
                setSelected(value);
            }
            set_isAllSelected(
                selectAllIntegrate
                    ? options.length > 0 && selected.length === options.length
                    : false
            );
        }

        return () => {};
    }, [value, options, selectAllIntegrate, selected]);

    const [isAllSelected, set_isAllSelected] = useState(
        selectAllIntegrate
            ? options.length > 0 && selected.length === options.length
            : ''
    );

    const handleChange = (event) => {
        const value = event.target.value;

        if (value[value.length - 1] === 'all') {
            setSelected(selected.length === options.length ? [] : options);
            const simulatedEvent = {
                target: {
                    name: name,
                    value: selected.length === options.length ? [] : options,
                },
            };
            onChange(simulatedEvent);
            return;
        }
        setSelected(value);
        onChange(event);
    };
    return (
        <>
            <FormControl
                variant={variant}
                fullWidth
                {...(error && { error })}
                {...other}
            >
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    name={name}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    value={selected}
                    {...(selectAllIntegrate && { value: selected })}
                    {...(!selectAllIntegrate && { value: value })}
                    {...(selectAllIntegrate && { onChange: handleChange })}
                    {...(!selectAllIntegrate && { onChange: onChange })}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {isAllSelected && selectAllIntegrate && (
                                <Chip size="small" key="All" label="All" />
                            )}
                            {selected.map((val) => (
                                <Chip
                                    size="small"
                                    key={val.value}
                                    label={val.label || val}
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {selectAllIntegrate && (
                        <MenuItem value="all">
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={
                                    selected.length > 0 &&
                                    selected.length < options.length
                                }
                                // checked={value && value.filter(data => data.value === option.value).length > 0}
                            />
                            <ListItemText primary="Select All" />
                        </MenuItem>
                    )}

                    {!option_disable &&
                        options &&
                        options.map((option, index) => (
                            <MenuItem key={option.value} value={option.value}>
                                {/* {selectAllIntegrate && (
                                    <Checkbox
                                        checked={true && selected?.indexOf( option || option.value) > -1}
                                    />
                                )}
                                {!selectAllIntegrate && (
                                    <Checkbox
                                        checked={true && value?.indexOf( option || option.value) > -1}
                                        
                                    />
                                )} */}
                                <ListItemText
                                    primary={option.label}
                                    secondary={option.email || false}
                                />
                            </MenuItem>
                        ))}
                    {option_disable &&
                        options &&
                        options.map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option}
                                disabled={
                                    option.value !== 'All'
                                        ? option_disable
                                        : false
                                }
                            >
                                {selectAllIntegrate && (
                                    <Checkbox
                                        checked={
                                            selected &&
                                            selected.filter(
                                                (data) =>
                                                    data.value === option.value
                                            ).length > 0
                                        }
                                    />
                                )}
                                {!selectAllIntegrate && (
                                    <Checkbox
                                        checked={
                                            value &&
                                            value.filter(
                                                (data) =>
                                                    data.value === option.value
                                            ).length > 0
                                        }
                                    />
                                )}
                                <ListItemText
                                    primary={option.label}
                                    secondary={option.email || false}
                                />
                            </MenuItem>
                        ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </>
    );
}

export default MultiSelect;
