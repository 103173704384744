import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Paper, Slide, Typography } from "@mui/material";
import Controls from '../../Components/Controls/Controls'
import { useForm } from '../../Components/UseForm';
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import API from '../../libs/ServerAPIs';
import { useSnackbar } from 'notistack';

export default function Home() {
    const [selectedFile, setSelectedFile] = useState();
    const [selectedImagePreview, setSelectedImagePreview] = useState();
    const [isFetchRequestActive, setIsFetchRequestActive] = useState(false);
    const [listingResults, setListingResults] = useState(null);
    const [isEmptyOrNoData, setIsEmptyOrNoData] = useState(false);
    const [isStatusReqCompleted, setIsStatusReqCompleted] = useState(true);
    const [loadingAbortReq, setLoadingAbortReq] = useState(false);
    const [isStatusIntervalCleared, setIsStatusIntervalCleared] = useState(true);

    const conditionOptions = [{ value: "NEW", label: "New" }, { value: 'USED', label: 'Used' }, { value: 'OPEN_BOX', label: 'Open Box' }, { value: 'REFURBISHED', label: 'Refurbished' }, { value: 'BROKEN', label: 'Broken' }, { value: 'OTHER', label: 'Other' }];

    const initValues = {
        zipCode: '',
        searchTerm: '',
        conditions: [],
        image: '',
        stopOnFirstMatch: true,
        scrollToNthPages: '',
        category: '',
        subCategory: '',
        distance: '',
        priceMin: '',
        priceMax: ''
    };

    const { enqueueSnackbar } = useSnackbar();

    const {
        var_values,
        set_values,
        var_errors,
        set_errors,
        handleInputChange,
    } = useForm(initValues);

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            resumeHideDuration: 5000,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    const validate_search_criteria = (fieldValues = var_values) => {
        let temp = { ...var_errors };
        let isValid = true;
        if (!fieldValues.image || fieldValues.image === '' || fieldValues.image === undefined) {
            temp.image = 'Please select an image to search.';
            isValid = false;
        }
        if (!fieldValues.searchTerm || fieldValues.searchTerm === '') {
            temp.searchTerm = 'Please enter a search term to search.';
            isValid = false;
        }
        if (
            !fieldValues.zipCode ||
            fieldValues.zipCode === '' || fieldValues.zipCode.length < 5 || fieldValues.zipCode === 0
        ) {
            temp.zipCode = 'Please enter a valid zip code with at least 5 characters';
            isValid = false;
        }
        set_errors({
            ...temp,
        });
        return isValid;
    };

    const submitImageSearchRequest = async (formData) => {
        try {
            setListingResults(null);
            setIsEmptyOrNoData(false);

            const createRequest = await API.post(`/api/offer-up`, formData, true);

            if (createRequest?.success) {
                showSnackbar('info', 'Your image search request has been successfully created on the backend API server.');

                if (isStatusIntervalCleared) {
                    console.log('Start interval')
                    setIsStatusIntervalCleared(false);
                    const getStatusIntervalReq = setInterval(() => {
                        checkRequestStatus(getStatusIntervalReq);
                    }, 10000);
                }
            } else {
                setIsFetchRequestActive(false);
                showSnackbar('error', createRequest.message);
            }
        } catch (error) {
            showSnackbar('error', "Something went wrong! Please try again later");
            setIsFetchRequestActive(false);
        }
    }

    async function onSubmit() {
        if (validate_search_criteria()) {
            setIsFetchRequestActive(true);
            var formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('searchTerm', var_values.searchTerm);
            formData.append('conditions', var_values.conditions);
            formData.append('zipCode', var_values.zipCode);
            formData.append('stopOnFirstMatch', var_values.stopOnFirstMatch);

            if (var_values.scrollToNthPages) {
                formData.append(
                    'scrollToNthPages',
                    var_values.scrollToNthPages
                );
            }
            if (var_values.category) {
                formData.append(
                    'category',
                    var_values.category
                );
            }
            if (var_values.subCategory) {
                formData.append(
                    'subCategory',
                    var_values.subCategory
                );
            }
            if (var_values.distance) {
                formData.append(
                    'distance',
                    var_values.distance
                );
            }
            if (var_values.priceMin) {
                formData.append(
                    'priceMin',
                    var_values.priceMin
                );
            }
            if (var_values.priceMax) {
                formData.append(
                    'priceMax',
                    var_values.priceMax
                );
            }

            submitImageSearchRequest(formData);
        }
    }

    async function checkRequestStatus(intervalReq) {
        if (!isStatusReqCompleted) {
            return false;
        }

        if (loadingAbortReq) {
            return false;
        }

        setIsStatusReqCompleted(false);

        return API.get(`/api/offer-up/status`).then(
            (resStatus) => {
                if (resStatus?.success) {
                    setListingResults(resStatus);
                }
                if (resStatus?.data?.isSearchRequestCompleted) {
                    console.log('Clear interval on Success')
                    clearInterval(intervalReq);
                    setIsStatusIntervalCleared(true);
                    if (!resStatus?.data?.listings?.length) {
                        setIsEmptyOrNoData(true);
                    }
                    setIsFetchRequestActive(false);
                }
                showSnackbar('info', resStatus.message);
                setIsStatusReqCompleted(true);
            }
        ).catch(error => {
            setIsStatusReqCompleted(true);
        });
    }

    async function abortRequest() {
        setLoadingAbortReq(true);

        return API.get(`/api/offer-up/abort`).then(
            (resStatus) => {
                if (!resStatus.error) {
                    setListingResults(resStatus);
                    if (!resStatus?.data?.listings?.length) {
                        setIsEmptyOrNoData(true);
                    }
                    showSnackbar('error', 'Fetch request aborted!')
                }
                setLoadingAbortReq(false);
                if (isStatusReqCompleted) {
                    setIsFetchRequestActive(false);
                }
            }
        ).catch((error) => {
            showSnackbar('error', 'Something went wrong, please try later');
            setLoadingAbortReq(false);
        });
    }

    const handleChangeFile = (event) => {
        const { value } = event.target;
        if (event.target.files[0]?.size > 10485760) {
            set_errors({
                ...var_errors,
                image:
                    'File size is too large. Please select the file with size less than 10mb',
            });
            set_values({
                ...var_values,
                image: '',
            });
        } else if (event.target.files[0]?.type !== 'image/png' && event.target.files[0]?.type !== 'image/gif' && event.target.files[0]?.type !== 'image/jpeg') {
            set_errors({ ...var_errors, image: 'Please select an image file, accepted formats are png, gif, jpeg' });
            set_values({
                ...var_values,
                image: '',
            });
        } else {
            setSelectedFile(event.target.files[0]);
            set_errors({ ...var_errors, image: '' });
            set_values({
                ...var_values,
                image: value,
            });
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                setSelectedImagePreview(reader.result);
            }

        }
    };

    return (
        <>
            <Paper>

                <Box padding={'16px 72px'}>
                </Box>

                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    padding={'16px 72px'}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container gap={1}>

                        <Grid item xs={12} md={2}>
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Controls.File name="image"
                                    value={var_values.image}
                                    error={var_errors.image}
                                    onChange={handleChangeFile}
                                    disabled={isFetchRequestActive}
                                    chosenFile={
                                        selectedFile
                                            ? selectedFile.name
                                            : var_values.pdf_filename
                                                ? var_values.pdf_filename
                                                : 'No file chosen'
                                    }
                                />
                                <Box sx={{ backgroundColor: '#8080803d', width: '100%', textAlign: 'center' }}>
                                    {selectedImagePreview &&
                                        <>
                                            <img alt="Selected Preview" style={{ width: '150px', height: '150px' }} src={selectedImagePreview} />
                                            <Box sx={{ textAlign: 'center', backgroundColor: 'white', border: '1px solid #80808069' }}>
                                                <Typography>Preview</Typography>
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item container gap={2} md={9} xs={12}>

                            <Grid item xs={12} md={4}>
                                <Controls.Input name="searchTerm"
                                    label="Search Text"
                                    value={var_values.searchTerm}
                                    disabled={isFetchRequestActive}
                                    error={var_errors.searchTerm}
                                    onChange={handleInputChange}
                                />
                            </Grid>



                            <Grid item xs={12} md={3}><Controls.Input name="zipCode"
                                label="Zip Code"
                                type="integer"
                                disabled={isFetchRequestActive}
                                value={var_values.zipCode}
                                onChange={handleInputChange}
                                error={var_errors.zipCode} />
                            </Grid>

                            <Grid item xs={12} md={4}><Controls.Checkbox name="stopOnFirstMatch"
                                label="Stop On First Match"
                                value={var_values.stopOnFirstMatch}
                                onChange={handleInputChange}
                                disabled={isFetchRequestActive}
                                error={var_errors.stopOnFirstMatch} />
                            </Grid>

                            <Grid container justifyContent={'flex-end'}>
                                <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: { xs: "100%", md: 'auto' }, margin: { xs: '8px 0', md: '0' } }}
                                        disabled={isFetchRequestActive}
                                        color='success'
                                        type="button"
                                        onClick={onSubmit}>
                                        {!isFetchRequestActive ? 'Submit' : <CircularProgress sx={{ width: '25px!important', height: '25px!important' }} />}
                                    </Button>
                                    {isFetchRequestActive &&
                                        <Button
                                            variant="contained"
                                            sx={{ marginLeft: '8px' }}
                                            color='error'
                                            type="button"
                                            onClick={abortRequest}>
                                            {!loadingAbortReq ? 'Abort' : <CircularProgress sx={{ width: '25px!important', height: '25px!important' }} />}
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container gap={5}>

                        <Accordion sx={{ width: '100%' }}>
                            <AccordionSummary
                                sx={{ width: '100%' }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="optional-fields"
                                id="optional-fields"
                            >
                                Options
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container gap={5}>
                                    <Grid item xs={12} md={4}>
                                        <Controls.MultiSelect
                                            name="conditions"
                                            label="Conditions"
                                            disabled={isFetchRequestActive}
                                            options={conditionOptions}
                                            onChange={handleInputChange}
                                            value={var_values.conditions}
                                            error={var_errors.conditions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controls.Input name="scrollToNthPages"
                                            label="Find in Top Nth Pages"
                                            type="integer"
                                            disabled={isFetchRequestActive}
                                            value={var_values.scrollToNthPages}
                                            onChange={handleInputChange}
                                            error={var_errors.scrollToNthPages} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controls.Select name="category"
                                            label="Category"
                                            disabled={isFetchRequestActive}
                                            value={var_values.category}
                                            onChange={handleInputChange}
                                            error={var_errors.category} />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Controls.Select name="subCategory"
                                            label="Sub Category"
                                            value={var_values.subCategory}
                                            disabled={isFetchRequestActive}
                                            onChange={handleInputChange}
                                            error={var_errors.subCategory} />
                                    </Grid>
                                    <Grid item xs={12} md={3}><Controls.Input name="distance"
                                        label="Distance"
                                        type="integer"
                                        value={var_values.distance}
                                        disabled={isFetchRequestActive}
                                        onChange={handleInputChange}
                                        error={var_errors.distance} /></Grid>
                                    <Grid item xs={12} md={3}><Controls.Input name="priceMin"
                                        label="Price Min"
                                        type="integer"
                                        value={var_values.priceMin}
                                        disabled={isFetchRequestActive}
                                        onChange={handleInputChange}
                                        error={var_errors.priceMin} /></Grid>
                                    <Grid item xs={12} md={3}><Controls.Input name="priceMax"
                                        label="Price Max"
                                        type="integer"
                                        value={var_values.priceMax}
                                        disabled={isFetchRequestActive}
                                        onChange={handleInputChange}
                                        error={var_errors.priceMax} /></Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                    </Grid>

                </Box>

                <Box padding={'32px 72px'}>
                    {listingResults &&
                        <span>
                            <Typography> <b>Search Response:</b> {listingResults?.message} </Typography>
                            <Typography> <b>Start Time:</b> {listingResults?.data?.startTime} </Typography>
                            <Typography> <b>End Time:</b> {listingResults?.data?.endTime} </Typography>
                            <Typography> <b>Execution Time:</b> {listingResults?.data?.executionTime} </Typography>
                            <Typography> <b>Scrolled Pages:</b> {listingResults?.data?.scrollToNthPages} </Typography>
                            <Typography> <b>Listing Stopped Reason:</b> {listingResults?.data?.pageListingStoppedReason} </Typography>
                            <Typography> <b>Total Listing Count:</b> {listingResults?.data?.totalListingCount} </Typography>
                            <Typography> <b>Single Listing Processed Count:</b> {listingResults?.data?.singleListingProcessedCount} </Typography>
                            <Typography> <b>Single Listing Stopped Reason:</b> {listingResults?.data?.singleListingStoppedReason} </Typography>
                        </span>}
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container >
                                {listingResults && !isFetchRequestActive &&
                                    <Grid item xs={12} sx={{ padding: '24px 0;' }}>
                                        <Divider sx={{ border: '2px solid #00000038' }} />
                                    </Grid>
                                }

                                {listingResults && !isFetchRequestActive && listingResults?.data?.listings?.map((listing) => {
                                    return (
                                        <Grid key={`${listing.listingId}-div`} container>
                                            <Grid item md={4} xs={12}>
                                                <Box display={'flex'} gap={1} justifyContent={'center'} flexWrap={'wrap'} alignItems={'center'}>
                                                    {listing.images.map((image) => {
                                                        return (<img key={`${listing.listingId}-img`} alt="Listing" src={image} style={{ width: '250px', height: '175px' }} />)
                                                    })}
                                                </Box>
                                            </Grid>

                                            <Grid item md={8} xs={12} display={'flex'} alignItems={'center'}>
                                                <Box sx={{ padding: '12px 32px' }}>
                                                    <Typography>Listing ID: {listing.listingId}</Typography>
                                                    <Typography>Title: {listing.title}</Typography>
                                                    <Typography>Description: {listing.description}</Typography>
                                                    <Typography>Location: {listing.ownerNLocation}</Typography>
                                                    <Typography>Price: {listing.price}</Typography>
                                                    <Typography>Owner Name: {listing.ownerName}</Typography>
                                                    <Typography>OfferUp URL: <a href={listing.offerUpURL}>{listing.offerUpURL}</a></Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sx={{ padding: '24px 0;' }}>
                                                <Divider sx={{ border: '2px solid #00000038' }} />
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {isFetchRequestActive &&
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <CircularProgress sx={{ width: '150px!important', height: '150px!important' }} />
                                    </Grid>
                                }

                                {listingResults && !isFetchRequestActive && isEmptyOrNoData &&
                                    <>
                                        <Grid item xs={12} sx={{ padding: '24px 0;' }}>
                                            <Divider sx={{ border: '2px solid #00000038' }} />
                                        </Grid>
                                        <Grid item xs={12} display={"flex"} justifyContent={'center'} alignItems={'center'}>
                                            <Typography variant="h4">Oops! It seems there was no match found for your request</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ padding: '24px 0;' }}>
                                            <Divider sx={{ border: '2px solid #00000038' }} />
                                        </Grid>
                                    </>
                                }

                            </Grid>

                        </Grid>

                    </Grid>

                </Box>

            </Paper>
        </>
    )
}