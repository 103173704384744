import React from 'react';
import { TextField } from '@mui/material';

export default function Input(props) {
    const {
        name,
        label,
        value,
        type,
        helperText,
        variant = 'outlined',
        error = false,
        onChange,
        innerRef,
        inputLabelProps = {},
        ...other
    } = props;
    return (
        <>
            <TextField
                fullWidth
                variant={variant}
                InputLabelProps={inputLabelProps}
                label={label}
                type={type}
                name={name}
                ref={innerRef}
                value={value}
                onChange={onChange}
                helperText={helperText}
                {...(error && { error: true, helperText: error })}
                {...other}
            />
        </>
    );
}
